export default theme => ({
    root: {
        flex: 1,
        backgroundColor: 'white',
        border: '1px solid rgb(48, 148, 171)',
        borderRadius: "unset",
        padding: '8px 18px 8px 11px',
        textTransform: 'none',
        color: 'rgb(48, 148, 171)',

        '&:hover': {
            backgroundColor: 'white',
        }
    },
    disabled: {

    },
});
