import React from "react";
import Modal from './Modal';
import SearchForm from "./SearchForm"
import TurboSearchForm from "./TurboSearchForm";
//import styles = {};


// @ts-ignore
//const useStyles = makeStyles(styles);
const sortByOption = [
    {
        value: 'driving',
        label: 'Vezetés',
    },
    {
        value: 'class',
        label: 'Tanulás',
    },
    {
        value: 'sum',
        label: 'Minden',
    },
];

type FilterModalProps = {
    onClose: (e: Event) => void;
    open: boolean;
    defaultValues: {
        district: string;
        driving: string;
        class: string;
    };
    defaultDetailsOpen: boolean;
};

export default function FilterModal({onClose, open, defaultValues, defaultDetailsOpen}: FilterModalProps) {
    //const classes = useStyles();
    const district = defaultValues.district || 'ALL';
    const classFilter = defaultValues.class || 'class';
    const driving = defaultValues.driving || 'driving';
    const values = {...defaultValues, district, class: classFilter, driving};

    console.log(defaultValues);
    console.log(values);

    return (
        <Modal title="Szűrés" onClose={onClose} open={open}>
            <TurboSearchForm defaultValues={values} onSubmit={onClose} defaultDetailsOpen={defaultDetailsOpen}/>
        </Modal>
    );
};
