import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Badge from '@material-ui/core/Badge';
import Rating from '@material-ui/lab/Rating';
import Button from '@material-ui/core/Button';
import get from 'lodash/get';
import styles from './School.style';
import clsx from "clsx";
import {getMatchingAddress} from '../util/Util';
import {getInfoByCategory} from "../config/AppConfig";
import {Tag} from "./Tag";
import capitalize from 'lodash/capitalize';
import {FormLabel} from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";

const useStyles = makeStyles(styles);

const TurboSchool = (props) => {
    const classes = useStyles();
    const formater = new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const { data, handleDetailsClick, filter, sortBy } = props;
    const {
        city: cityFilter,
        district: districtFilter,
        category: categoryFilter,
        class: classFilter,
        transmission: transmissionFilter,
        driving: drivingFilter
    } = filter;
    const reviews = get(data, 'reviews', {});
    const info = getInfoByCategory(categoryFilter);
    const address = `${capitalize(cityFilter)}, ${getMatchingAddress(data.addresses, districtFilter)} ker.`;
    const prices = get(data, `categories.${categoryFilter.toUpperCase()}`, {});

    const sortedBySum = sortBy === 'sum';
    const sortedByClass = sortBy === 'class';
    const sortedByDriving = sortBy === 'driving';// @ts-ignore

    const handleClick = () => {
        handleDetailsClick(data.itemId);
    };
    const countComponent = (<span className={classes.ratingCount}>({reviews.count} vélemény)</span>);
    const reviewComponent = reviews.link ? (<a href={reviews.link} target="_blank">{countComponent}</a>) : countComponent;
    let drivindPrice = prices[drivingFilter] === 'na' ? 0 : prices[drivingFilter];
    const classPrice = prices[classFilter] === 'na' ? 0 : prices[classFilter];
    if (transmissionFilter === 'drivingAuto') {
        drivindPrice += prices.drivingAuto;
    }
    const priceSum = (drivindPrice && classPrice) ? drivindPrice * 30 + classPrice : prices.sum;

    data.special = false;

    return (
        <Card variant="outlined" classes={{root: classes.cardRoot}}>
            { data.sale && <div className={classes.badge} data-label="AKCIÓ" /> }
            { data.special && <CardMedia
                className={classes.media}
                image="/images/cash_back.jpg"
                title="Contemplative Reptile"
            />}
            <CardContent>
                <div className={classes.schoolName}>
                    {data.name}
                </div>
                <div className={classes.addresses}>
                    <span>{address}</span>
                </div>
                <div>
                    { reviews.value && (
                        <span className={classes.rating}>
                                { reviews.value && (<span className={classes.ratingValue}>{reviews.value} </span>) }
                            <Rating name="read-only" value={reviews.value} readOnly size="small" />
                            { reviews.count && reviewComponent }
                            </span>
                    )}
                </div>
                { categoryFilter === 'b' && (
                    <div className={classes.tags}>
                        <Tag text="E-learning" tooltip="E-learning" active={prices.eClass} />
                        <Tag text="intenzív" tooltip="Gyors jogosítvány. Általában heti háromszor 2 óra vezetés. Felár ellenében!" active={prices.drivingQuick} />
                        <Tag text="VIP" tooltip="Általában elsőbségi háztól-házig vezetés. Felár ellenében!" active={prices.drivingVip} />
                        <Tag text="esti vezetés" tooltip="Általában 17.00 után. Felár ellenében!" active={prices.drivingEvening} />
                        <Tag text="hétvégi vezetés" tooltip="Hétvégi vezetés. Felár ellenében!" active={prices.drivingWeekend} />
                        <Tag text="automata váltó" tooltip="Vezetés automata váltós autón. Felár ellenében!" active={prices.hasOwnProperty('drivingAuto')} />
                        <Tag text="gyakorló vezetés" tooltip="Jogosítvánnyal rendelkezök számára. Felár ellenében!" active={prices.drivingPractice} />
                    </div>
                )}
                <div className={classes.prices}>
                    <div className={classes.priceItem}>
                        <div className={clsx(classes.label, {[classes.sortByLabel]: sortedByClass})}>
                            <div>Elméleti (KRESZ) tanfolyam</div>
                            {info[classFilter] && <div className={classes.infoText}>{info[classFilter]}</div>}
                        </div>
                        <div className={clsx(classes.price, {[classes.sortByPrice]: sortedByClass})}>
                            {data.sale && <div className={classes.original}>{prices[classFilter] === 'na' ? '-' : `${formater.format(prices[classFilter])}Ft`}</div>}
                            {data.sale && <div className={classes.sale}>{prices[classFilter] === 'na' ? '-' : `${formater.format(prices[classFilter])}Ft`}</div>}
                            {data.sale ? '' : prices[classFilter] === 'na' ? '-' : `${formater.format(prices[classFilter])}Ft`}
                        </div>
                    </div>

                    <div className={classes.priceItem}>
                        <div className={clsx(classes.label, {[classes.sortByLabel]: sortedByDriving})}>
                            <div className={classes.labelInner}>
                                <div>{info[`${drivingFilter}Label`]} Vezetés / óra</div>
                                {transmissionFilter === 'drivingAuto' && <div className={classes.autoIcon}></div>}
                            </div>
                            {info[drivingFilter] && <div className={classes.infoText}>{info[drivingFilter]}</div>}
                        </div>
                        <div className={clsx(classes.price, {[classes.sortByPrice]: sortedByDriving})}>
                            {data.sale && <div className={classes.original}>{prices[drivingFilter] === 'na' ? '-' : `${formater.format(prices[drivingFilter])}Ft`}</div>}
                            {data.sale && <div className={classes.sale}>{prices[drivingFilter] === 'na' ? '-' : `${formater.format(prices[drivingFilter])}Ft`}</div>}
                            {data.sale ? '' : prices[drivingFilter] === 'na' ? '-' : `${formater.format(drivindPrice)}Ft`}
                        </div>
                    </div>


                    <div className={classes.priceItem}>
                        <div className={clsx(classes.label, {[classes.sortByLabel]: sortedBySum})}>
                            <div>
                                Összesen
                            </div>
                            {info.class && <div className={classes.infoText}>{info.sum}</div>}
                        </div>
                        <div className={clsx(classes.price, {[classes.sortByPrice]: sortedBySum})}>
                            {data.sale && <div className={classes.original}>{`${formater.format(priceSum)}Ft`}</div>}
                            {data.sale && <div className={classes.sale}>{`${formater.format(priceSum)}Ft`}</div>}
                            {data.sale ? '' : `${formater.format(priceSum)}Ft`}
                        </div>
                    </div>
                </div>
            </CardContent>
           {/* <div className={classes.specialInfo}>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
            </div>*/}
            <CardActions classes={{root: classes.cardActionRoot}}>
                <Button color="primary" onClick={handleClick} className={classes.moreInfoButton}>
                    Részletek
                </Button>
            </CardActions>
        </Card>

    );
}

export default TurboSchool;
