export default theme => ({
    cardRoot: {
        position: 'relative',
        overflow: 'visible',
        borderRadius: 4,
        backgroundColor: theme.palette.background.main,
        boxShadow: '0 2px 4px rgb(0 0 0 / 20%)',
        width: '96%',
        minWidth: 300,
        maxWidth: 450,
        marginBottom: 25,
        '& .MuiCardActions-root': {
            justifyContent: 'center',
        },
    },
    media: {
        height: 100,
    },
    schoolName: {
        fontFamily: theme.typography.fontFamily,
        fontSize: 18,
        fontWeight: 700,
        color: theme.palette.grey[600],
        marginRight: theme.spacing(2),
        // color: 'rgba(48, 148, 171)'

    },
    rating: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    ratingCount: {
        marginLeft: theme.spacing(1),
        color: theme.palette.grey[500],
        fontSize: 10,
    },
    ratingValue: {
        // marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(0.5),
        color: theme.palette.highlighted,
        fontSize: 20,
    },
    addresses:{
        fontFamily: theme.typography.fontFamily,
        fontSize: 14,
        fontWeight: 400,
        color: theme.palette.grey[500],

    },
    prices: {
        fontFamily: theme.typography.fontFamily,
        marginTop: 10,
    },
    priceItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    label: {
        maxWidth: '65%',
        color: theme.palette.grey[600],
        fontSize: 12,
        marginBottom: 12,
        fontWeight: 550,
        '& div:first-child': {
            marginRight: 4,
        },

    },
    infoText: {
        color: '#d5d5d5',
        fontSize: 10,
    },
    sortByLabel: {
        color: theme.palette.highlighted,
        fontSize: 14,
    },
    price: {
        color: theme.palette.grey[700],
        fontSize: 14,
        fontWeight: 600,
    },
    original: {
        fontSize: 12,
        textDecoration: 'line-through'
    },
    sale: {
        color: 'rgba(255, 66, 0)',
    },
    sortByPrice: {
        fontSize: 16,
        color: theme.palette.highlighted,
    },
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    tags:{
        margin: '10px 0px 20px 0px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 5,
    },
    cardActionRoot: {
        justifyContent: 'flex-end !important',
    },
    moreInfoButton: {
        color: '#0175FF',
        fontWeight: 'bold',
    },
    labelInner: {
        display: 'flex',
        gap: 8,
        alignItems: 'center',
    },
    autoIcon: {
        width: 33,
        height: 37,
        background: 'url("/images/auto.jpg") no-repeat center center',
        transform: 'rotate(90deg);',
    },

    badge: {
        width: '25rem',
        borderRadius: '1rem',
        position: 'absolute',
        color: '#434343',
        '&::after': {
            borderRadius: 3,
            position: 'absolute',
            content: 'attr(data-label)',
            top: -17,
            right: 50,
            padding: '0.3rem',
            width: '8rem',
            background: 'rgba(255, 66, 0, 0.8)',
            color: 'white',
            textAlign: 'center',
            boxShadow: '4px 4px 15px rgba(26, 35, 126, 0.2)',
        }
    },

    specialInfo: {
        width: '89%',
        backgroundColor: '#f1f2f5',
        color: '#757575',
        padding: '24px 20px',
        fontWeight: 550,
        fontSize: 12,
        lineHeight: '1.5em'
    }
});
