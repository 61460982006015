import React, {useCallback, useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import styles from './Schools.style';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import FilterModal from "../components/FilterModal";
import DetailsModal from "../components/DetailsModal";
import School from "./School";
import SortByButton from "./SortByButton";
import PlainButton from "../components/PlainButton";
import {useContextState} from "../context/State";
import {useParams} from "react-router-dom";
import {findSchoolById} from '../util/Util';
import intersection from 'lodash/intersection';
import capitalize from 'lodash/capitalize';
import useQuery from "../hooks/Query";
import {Helmet} from 'react-helmet';
import TurboSchool from "./TurboSchool";
import {getInfoByCategory} from "../config/AppConfig";

const DialogTitle = props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography {...other}>
            <Typography variant="h6">{children}</Typography>

                <IconButton aria-label="close" style={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: '#e7e7e7e',
                    }} onClick={()=>{}}>
                    <CloseIcon />
                </IconButton>

        </MuiDialogTitle>
    );
};

const tagsFilterStringToArray = query => {
    const tags = query.get('tags');

    return tags && tags.length ? tags.split(',') : [];
}

const filterAndSortSchools = (schools, place, category, districts, sortBy, tagsFilter) => {
    const categoryFilter = category.toUpperCase();
    const compareFn = function (a, b) {
        let aVal = 0;
        let bVal = 0;

        if (tagsFilter.class && tagsFilter.driving) {
            if (sortBy === 'driving') {
                aVal = a.categories[categoryFilter][tagsFilter.driving];
                aVal = aVal === 'na' ? 999999999 : aVal;
                if (tagsFilter.transmission === 'drivingAuto') {
                    aVal += a.categories[categoryFilter].drivingAuto;
                }

                bVal = b.categories[categoryFilter][tagsFilter.driving];
                if (tagsFilter.transmission === 'drivingAuto') {
                    bVal += b.categories[categoryFilter].drivingAuto;
                }
                bVal = bVal === 'na' ? 999999999 : bVal;
            }

            if (sortBy === 'class') {
                aVal = a.categories[categoryFilter][tagsFilter.class];
                aVal = aVal === 'na' ? 999999999 : aVal;
                bVal = b.categories[categoryFilter][tagsFilter.class];
                bVal = bVal === 'na' ? 999999999 : bVal;

            }

            if (sortBy === 'sum') {
                const aDriving = a.categories[categoryFilter][tagsFilter.driving];
                const aClass = a.categories[categoryFilter][tagsFilter.class];
                let aDrivingPrice = aDriving === 'na' ? 0 : aDriving;
                const aClassPrice = aClass === 'na' ? 0 : aClass;
                if (tagsFilter.transmission === 'drivingAuto') {
                    aDrivingPrice += a.categories[categoryFilter].drivingAuto;
                }
                aVal = (aDrivingPrice && aClassPrice) ? aDrivingPrice * 30 + aClassPrice : a.categories[categoryFilter].sum;

                const bDriving = b.categories[categoryFilter][tagsFilter.driving];
                const bClass = b.categories[categoryFilter][tagsFilter.class];
                let bDrivingPrice = bDriving === 'na' ? 0 : bDriving;
                const bClassPrice = bClass === 'na' ? 0 : bClass;
                if (tagsFilter.transmission === 'drivingAuto') {
                    bDrivingPrice += b.categories[categoryFilter].drivingAuto;
                }
                bVal = (bDrivingPrice && bClassPrice) ? bDrivingPrice * 30 + bClassPrice : b.categories[categoryFilter].sum;
            }
        } else {
            aVal = a.categories[categoryFilter][sortBy];
            bVal = b.categories[categoryFilter][sortBy];
        }


      if (aVal > bVal) return 1;
      if (bVal > aVal) return -1;

      return 0;
    };

  const filtered = schools.filter(item => {
      if (item.disabled) {
          return false;
      }

      const { addresses = [] } = item;
      const cities = addresses.map(item => item.city.toLowerCase());
      const cityMatch = cities.includes(place.toLowerCase());
      if (!cityMatch) {
          return false;
      }

      let districtMatch = true;
      if (districts && districts.length) {
         districtMatch = intersection(item.districts , districts.split(',')).length;
      }
      if (!districtMatch) {
          return false;
      }

      const categoryMatch = !!(item.categories && item.categories[categoryFilter]);
      if (!categoryMatch) {
          return false;
      }

      let queryMatch = true;
      if (tagsFilter.class /* && tagsFilter.transmission */ && tagsFilter.driving) {
          const categoryData = item.categories[categoryFilter];
          const classMatch = categoryData.hasOwnProperty(tagsFilter.class);
          const transmissionMatch = tagsFilter.transmission === 'manual' || (tagsFilter.transmission === 'drivingAuto' && categoryData.hasOwnProperty('drivingAuto'));
          const drivingMatch = categoryData.hasOwnProperty(tagsFilter.driving);

          queryMatch = classMatch && transmissionMatch && drivingMatch;

      }
      if (!queryMatch) {
          return false;
      }

      return true;
  });

  filtered.sort(compareFn);

  return filtered;
};

const useStyles = makeStyles(styles);

const Schools = () => {
    const classes = useStyles();
    const {state, dispatch} = useContextState();
    const { sortBy } = state;
    let query = useQuery();
    const tagsFilter = {
        class: query.get("class"),
        transmission: query.get("transmission"),
        driving: query.get("driving")
    };
    const tagsFilterForSearchForm = tagsFilter.length ? tagsFilter.reduce((acc, t) => {
        acc[t] = true;

        return acc;
    }, {}) : {};
    const {cityFilter, categoryFilter, districtFilter} = useParams();
    const placeName = `${capitalize(cityFilter)} ${districtFilter}`;
    // const category = findCategoryByValue(categories);
    const schools = filterAndSortSchools(state.data, cityFilter, categoryFilter, districtFilter, sortBy, tagsFilter);

    const [filterModal, setFilterModal] = useState(false);
    const [selectedSchool, setSelectedSchool] = useState(false);
    const onFilterPanelClose = React.useCallback(() => {
        setFilterModal(false);
    }, [setFilterModal]);
    const onFilterPanelOpen = React.useCallback(() => {
        setFilterModal(true);
    }, [setFilterModal]);
    const handleDetailsClick = useCallback((schoolId) => {
        dispatch({
            type: 'setSelectedSchool',
            payload: {
                value: schoolId
            },
        });
    }, [setSelectedSchool]);

    const onDetailsPanelClose = React.useCallback(() => {
        setSelectedSchool(false);
    }, [setSelectedSchool]);

    let school = null;
    if (selectedSchool) {
        school = findSchoolById(schools, selectedSchool);
    }

    const title = `"${categoryFilter.toUpperCase()}" kategóriás jogosítvány, autós jogosítvány, autósiskolák, kresz tanfolyamok`;
    const keywords =`jogosítvány, autó jogosítvány,  jogosítvány, jogosítvány árak, ${categoryFilter} jogosítvány árak, autósiskolák, autósiskola budapest, jogsi, jogsi képzés, jogosítvány oktatás`;
    const description =`olcsó ${categoryFilter.toUpperCase()} kategóriás jogosítvány, ${categoryFilter.toUpperCase()} kategóriás jogosítvány ára, autósiskolák budapesten, budapesti autósiskolák, ${categoryFilter} kategóriás jogosítvány e-learning, online kresz`;
    const SchoolComponent = categoryFilter.toLowerCase() === 'b' ? TurboSchool : School;
    const info = getInfoByCategory(categoryFilter);
    let filterText = ''
    if (tagsFilter.class || tagsFilter.driving || tagsFilter.transmission) {
        filterText += ` (`;
    }
    if (tagsFilter.class) {
        filterText += info[tagsFilter.class];
    }
    if (tagsFilter.driving) {
        const key = `${tagsFilter.driving}Label`;

        if (info[key]) {
            filterText += `, ${info[key]} Vezetés`;
        }
    }
    if (tagsFilter.transmission) {
        filterText += `, ${info[tagsFilter.transmission]}`;
    }
    if (tagsFilter.class || tagsFilter.driving || tagsFilter.transmission) {
        filterText += `)`;
    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
            </Helmet>
            <div className={classes.container}>
            <div className={classes.stickyTop}>
                <div className={classes.controls}>
                    <PlainButton icon={<SearchIcon />} text="Szűrés" onClick={onFilterPanelOpen} />
                    <SortByButton />
                </div>
            </div>
            <div className={classes.content}>
                <div className={classes.pageTitle}>
                    <span>{schools.length} </span>
                    autósiskolát találtunk <span>Budapesten</span>
                    <span>{districtFilter ? ` (${districtFilter} ker.)` : ''}</span>, ahol
                    <span> {capitalize(categoryFilter)} kategóriás </span>
                    jogosítvány megszerzése lehetséges<b>{filterText}</b>.
                </div>

                <div className={classes.tileList}>
                {
                    schools.map(
                      item => (<SchoolComponent key={item.itemId} data={item} handleDetailsClick={handleDetailsClick} filter={{
                                  city: cityFilter,
                                  district: districtFilter,
                                  category: categoryFilter,
                                  ...tagsFilter
                              }} sortBy={sortBy} />)
                    )
                }
                </div>

                {  filterModal && <FilterModal
                    open={filterModal}
                    defaultValues={{
                        ...tagsFilter,
                        city: cityFilter,
                        district: districtFilter,
                        category: categoryFilter,
                    }}
                    defaultDetailsOpen={true}
                    onClose={onFilterPanelClose}
                />
                }
            </div>
        </div>
        </>
    );
}

export default Schools;
