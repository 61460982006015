import React from 'react';
import {Route, Switch} from "react-router-dom";
import Home from "./home/Home";
import Schools from "./schools/Schools";
import Page from "./page/Page";
import useGaTracker from "./useGaTracker";

const Roots: React.FC = () => {
    //useGaTracker();

    return (
        <Switch>
            <Route path="/adatvedelmi-szabalyzat">
                <Page
                    title="Adatvédelmi Szabályzat"
                    content="2011. augusztus 3-án lépett hatályba az elektronikus hírközlési törvény új rendelkezése, amely az internetes cookie-kat hivatott szabályozni. Az elektronikus hírközlésről szóló 2003. évi C. törvény új rendelkezése 155. § (4) Egy előfizetőnek vagy felhasználónak elektronikus hírközlő végberendezésén csak az érintett felhasználó vagy előfizető világos és teljes körű – az adatkezelés céljára is kiterjedő – tájékoztatását követő hozzájárulása alapján lehet adatot tárolni, vagy az ott tárolt adathoz hozzáférni."
                />
            </Route>
            <Route path={["/:categoryFilter-kategorias/jogositvany/:cityFilter/:districtFilter", "/:categoryFilter-kategorias/jogositvany/:cityFilter"]}>
                <Schools />
            </Route>
            <Route path="/">
                <Home />
            </Route>
        </Switch>
    );
}

export default Roots;
