import React, {useCallback} from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router";

const useStyles = makeStyles({
    root: {
        boxShadow: '0 2px 4px rgb(0 0 0 / 20%)',
        marginTop: 30,
        borderRadius: 4,
        color: '#243238',
    },
    media: {
        height: 140,
    },
    cardActionsRoot: {
      justifyContent: 'space-between',
    },
    schoolsButtonRoot: {
        backgroundColor: '#6ba229',
    }
});

const GovSup = () => {
    const classes = useStyles();
    const history = useHistory();

    const handleDetailsClick = useCallback(() => {
        window.open('https://folyositas.tcs.allamkincstar.gov.hu/t%C3%A1j%C3%A9koztat%C3%B3k/28-a-k%C3%B6zleked%C3%A9si-alapismeretek-tanfolyam-%C3%A9s-vizsga-d%C3%ADj%C3%A1hoz-ny%C3%BAjtott-t%C3%A1mogat%C3%A1s.html','_blank');
    });

    const handleSchoolsClick = useCallback(() => {
        history.push('/b-kategorias/jogositvany/budapest?class=class&driving=driving&transmission=manual');
    });

    return (<Card className={classes.root}>
        <CardActionArea>
            <CardMedia
                className={classes.media}
                image="/images/cash_back.jpg"
                title="Contemplative Reptile"
            />
            <CardContent>
                <Typography gutterBottom variant="subtitle2">
                    Az állam 25.000 Ft-tal támogatja a sikeres KRESZ vizsgát végzőket!
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    Ez vonatkozik a 20 év alattiak részére, illetve a CSED-en, GYES-en, GYED-en lévőkre.
                </Typography>
            </CardContent>
        </CardActionArea>

        <CardActions classes={{root: classes.cardActionsRoot}}>
            <Button
                onClick={handleDetailsClick}
                size="small"
                color="secondary"
            >
                Részletek
            </Button>
            <Button
                onClick={handleSchoolsClick}
                classes={{root: classes.schoolsButtonRoot}}
                variant="contained"
                color="primary"
                size="large"
            >
                Iskolák
            </Button>
        </CardActions>
    </Card>);
};

export default GovSup;
