import React, {useCallback} from "react";
import {makeStyles} from '@material-ui/core/styles';
import Modal from './Modal';
import {getMatchingAddress} from '../util/Util';
import Typography from '@material-ui/core/Typography';
import {getInfoByCategory} from '../config/AppConfig';
import {useContextState} from "../context/State";
import {useParams} from "react-router-dom";
const styles = {
    section: {
        marginTop: 12,
    }
};

// @ts-ignore
const useStyles = makeStyles(styles);

type ModalProps = {
    onClose: (e: Event) => void;
    open: boolean;
    selectedSchool: string;
    school: {
        name: string;
        addresses: [{}];
        desc: string;
        email: string;
        phone: [];
        web: string;
    };
    districts: string;
    categories: string;
    filter: {
        city: string;
        district: string;
        category: string;
    }
};

export default function DetailsModal() {
    const classes = useStyles();
    const {state, dispatch} = useContextState();
    const {selectedSchool} = state;
    const open = !!selectedSchool;

    const onClose = useCallback(() => {
        dispatch({
            type: 'setSelectedSchool',
            // @ts-ignore
            payload: {
                value: ''
            },
        });
    }, [dispatch]);
    const {cityFilter, categoryFilter, districtFilter} = useParams();
    // @ts-ignore
    const school = state.data.find(d => d.itemId === selectedSchool) || {};
    // @ts-ignore
    const { name, addresses, desc, email, phone, web } = school;
    /*const {
        city: cityFilter,
        district: districtFilter,
        category: categoryFilter,
    } = filter;*/

    if (!selectedSchool) {
        return null;
    }

    return (
        <Modal title={name} onClose={onClose} open={open} fullScreen>
            <Typography variant="subtitle2" className={classes.section}>Cím:</Typography>
            <Typography variant="body1">{getMatchingAddress(addresses, categoryFilter, true)}</Typography>

            <Typography variant="subtitle2" className={classes.section}>Email:</Typography>
            <Typography variant="body1">{email}</Typography>

            <Typography variant="subtitle2" className={classes.section}>Tel.:</Typography>
            <Typography variant="body1">{phone.join(', ')}</Typography>

            <Typography variant="subtitle2" className={classes.section}>Webhely:</Typography>
            <Typography variant="body1"><a href={web} target="_blank">{web}</a></Typography>

            <Typography variant="body2" className={classes.section}>{desc}</Typography>
        </Modal>
    );
};
