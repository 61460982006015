import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import AppConfig from "../config/AppConfig";

const useStyles = makeStyles({
    linksBlock: {
      minWidth: 280
    },
    categoryTitle: {
        fontWeight: 700,
    },
    list: {
        listStyleType: 'none',
        padding: '0 0 24px 0px',
    },
    listItem: {
        '& h4': {
            margin: 3,
        },
        '& a': {
            textDecoration: 'auto',
        },
        '& p': {
            color: '#243238',
        }
    }
});

const {places, categories} = AppConfig;
const getHref = (district, cat) => district !== 'ALL' ? `${cat.toLowerCase()}-kategorias/jogositvany/budapest/${district}/` : `${cat.toLowerCase()}-kategorias/jogositvany/budapest`;

const Links = () => {
  const classes = useStyles();

  return (
      <>
          {Object.keys(categories).map(cat => {
              const list = (
                  <div className={classes.linksBlock} key={cat}>
                      <Typography variant="body1" className={classes.categoryTitle}>{cat} kategória</Typography>
                      <ul className={classes.list}>
                          {places.map(p => (
                              <li className={classes.listItem} key={`${p.value}-${cat}`}><h4><a
                                  title={`Budapesti autósiskolák listája ${cat} kategóriás jogosítvány megszerzéséhez`}
                                  href={getHref(p.value, cat)}
                              >
                                  <Typography variant="body2">{cat} kategóriás jogosítvány, {p.label}</Typography>
                              </a></h4></li>
                          ))}
                      </ul>
                  </div>
              );

              return list;
          })}
      </>
  );
};

export default Links;
