import React from "react";
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// @ts-ignore
const DialogTitle = props => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography {...other}>
            <Typography variant="h6">{children}</Typography>

                <IconButton aria-label="close" style={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: '#e7e7e7e',
                    }} onClick={onClose}>
                    <CloseIcon />
                </IconButton>

        </MuiDialogTitle>
    );
};

export default DialogTitle;
