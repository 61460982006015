export default theme => ({
    formControl: {
        width: '100%',
        marginBottom: theme.spacing(2),

        '& .MuiInputBase-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
        }
    },
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
        },
        '& .MuiSelect-filled': {
            backgroundColor: '#FFF',
            border: '1px solid rgb(48, 148, 171)',
            borderRadius: 7,
        },
        '& .MuiSelect-icon': {
            color: 'rgb(48, 148, 171)',
        }
    },
    optionName: {
        fontSize: 12,
        marginLeft: 5,
    },
    submitButtonContainer: {
      position: 'relative',
    },
    submitButtonRoot: {
        width: '100%',
        backgroundColor: '#6ba229',
        fontWeight: 500
    },
    detailSearch: {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        border: '1px #d5d5d5 solid',
        padding: 18,
        borderRadius: 4,
        marginBottom: 15,
        marginTop: 5,
    },

    detailSearchFormControl: {
        marginBottom: 16,
        display: 'block'
    },
    formLabel: {
        marginBottom: 6,
        fontSize:14,
        fontWeight: 600,
        color: '#616161' // #616161, #757575 ,#d5d5d5
    },
    formControlLabel: {
        color: '#757575',
        '& .MuiIconButton-root': {
            padding: '4px 8px'
        },
        '& .MuiFormControlLabel-label': {
            fontSize: 14,
        }
    },
    formHelperText: {
        marginTop: 0,
        marginLeft: 27,
        color: '#d5d5d5'
    },
    radioGroup: {
        display: 'flex',
        justifyContent: 'space-between',
    },



    detailSearchButtonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: 15,
    },
    detailSearchButton: {
        fontSize: 12,
    },
    buttonProgress: {
        // color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});
