import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from "./DialogTitle";

//import styles = {};
// @ts-ignore
//const useStyles = makeStyles(styles);

type ModalProps = {
    title: string;
    children: React.ReactNode;
    onClose: (e: Event) => void;
    open: boolean;
    fullScreen?: boolean;
};

export default function Modal ({title, children, open, onClose, fullScreen = false}: ModalProps) {
    //const classes = useStyles();

    return (
        <Dialog onClose={onClose} open={open} fullScreen={fullScreen}>
            <DialogTitle onClose={onClose}>
                {title}
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
    );
}
