import React, {useCallback, useState} from 'react';
import {useHistory} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import {FormLabel, Radio, RadioGroup, FormHelperText} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import styles from './TurboSearchForm.style';
import AppConfig from "../config/AppConfig"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(styles);

const { places, categories } = AppConfig;

const TurboSearchForm = (props) => {
    const classes = useStyles();
    const { defaultValues={}, defaultDetailsOpen = false } = props;
    const [values, setValues] = useState(defaultValues);
    const [detailSearch, setDetailSearch] = useState(defaultDetailsOpen);
    const [isDetailSearchAvailable, setIsDetailSearchAvailable] = useState(defaultValues.category.toLowerCase() === 'b');
    const [loading, setLoading] = useState(false);

    const onChange = useCallback((e) => {
        if ( e.target.name === 'category') {
            if (e.target.value.toLowerCase() === 'b') {
                setValues({...defaultValues, district: values.district, city: values.city, [e.target.name]: e.target.value });
            } else {
                setValues({district: values.district, city: values.city, [e.target.name]: e.target.value});
            }
            setDetailSearch(false);
            setIsDetailSearchAvailable(e.target.value.toLowerCase() === 'b');
        } else {
            setValues({...values, [e.target.name]: e.target.value});
        }
    }, [values, setValues]);

    const history = useHistory();
    const onSubmit = useCallback(() => {
        setLoading(true);
        const {district = 'ALL', category} = values;
        const districtUrl = district !== 'ALL' ? `/${district}/` : '';
        let url = `/${category.toLowerCase()}-kategorias/jogositvany/budapest${districtUrl}`;

        if (values.class && values.driving) {
            url += `?class=${values.class}&driving=${values.driving}&transmission=${values.transmission}`;
        }

        history.push(url);

        if (props.onSubmit) {
            props.onSubmit(values);
        }
    }, [values, history, setLoading]);

    const handleDetailsSearchChange = useCallback((e) => {
        const newVal = !values[e.target.name];

        setValues({...values, [e.target.name]: e.target.value});
    }, [values, setValues]);

    const handleDetailSearchClick = useCallback(() => {
        setDetailSearch(!detailSearch);
    }, [detailSearch, setDetailSearch]);

    return (
        <>
            <form className={classes.form} noValidate autoComplete="off">
                    <FormControl variant="outlined" className={classes.formControl}>
                        <Select
                            name="district"
                            value={values.district}
                            onChange={onChange}
                        >
                            {places.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <Select
                            name="category"
                            value={values.category.toUpperCase()}
                            onChange={onChange}
                        >
                            {Object.keys(categories).map((key) => (
                                <MenuItem key={key} value={key} className={classes.option}>
                                    {categories[key].code} - <span className={classes.optionName}> {categories[key].name}</span>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>


                {isDetailSearchAvailable ? detailSearch ? (
                    <div className={classes.detailSearch}>
                        <FormControl component="fieldset" classes={{root: classes.detailSearchFormControl}}>
                            <FormLabel component="legend" classes={{root: classes.formLabel}}>Elméleti (KRESZ) tanfolyam</FormLabel>
                            <RadioGroup name="class" value={values.class} onChange={handleDetailsSearchChange} classes={{root: classes.radioGroup}}>
                                <FormControlLabel value="class" control={<Radio />} label="Tantermi" classes={{root: classes.formControlLabel}} />
                                <FormControlLabel value="eClass" control={<Radio />} label="E-learning" classes={{root: classes.formControlLabel}}/>
                            </RadioGroup>
                        </FormControl>

                       <FormControl component="fieldset"classes={{root: classes.detailSearchFormControl}}>
                            <FormLabel component="legend" classes={{root: classes.formLabel}}>Gépjármű</FormLabel>
                            <RadioGroup name="transmission" value={values.transmission} onChange={handleDetailsSearchChange} classes={{root: classes.radioGroup}}>
                                <FormControlLabel value="manual" control={<Radio />} label="Manuális" classes={{root: classes.formControlLabel}}/>
                                <FormControlLabel value="drivingAuto" control={<Radio />} label="Automata" classes={{root: classes.formControlLabel}}/>
                            </RadioGroup>
                       </FormControl>

                        <FormControl component="fieldset" classes={{root: classes.detailSearchFormControl}}>
                            <FormLabel component="legend" classes={{root: classes.formLabel}}>Gépjárművezetési gyakorlat</FormLabel>
                            <RadioGroup name="driving" value={values.driving} onChange={handleDetailsSearchChange} classes={{root: classes.radioGroup}}>
                                <FormControlLabel value="driving" control={<Radio />} label="Normál" classes={{root: classes.formControlLabel}}/>
                                <FormControlLabel value="drivingQuick" control={<Radio />} label="Intenzív" classes={{root: classes.formControlLabel}}/>
                                <FormHelperText classes={{root: classes.formHelperText}}>Általában hétfőtől péntekig heti minimum 3 alkalom, de ez iskolánként eltérhet.</FormHelperText>
                                <FormControlLabel value="drivingEvening" control={<Radio />} label="Esti" classes={{root: classes.formControlLabel}}/>
                                <FormHelperText classes={{root: classes.formHelperText}}>Általában hétfőtől péntekig 17.00 óra után, de ez iskolánként eltérhet.</FormHelperText>
                                <FormControlLabel value="drivingWeekend" control={<Radio />} label="Hétvégi" classes={{root: classes.formControlLabel}}/>
                                <FormHelperText classes={{root: classes.formHelperText}}>Általában hétvégi és ünnepnapi vezetés, de ez iskolánként eltérhet.</FormHelperText>
                                <FormControlLabel value="drivingPractice" control={<Radio />} label="Gyakorló" classes={{root: classes.formControlLabel}}/>
                                <FormHelperText classes={{root: classes.formHelperText}}>Gyakorló vezetés már jogosítvánnyal rendelkezők számára.</FormHelperText>
                                <FormControlLabel value="drivingVip" control={<Radio />} label="VIP" classes={{root: classes.formControlLabel}}/>
                                <FormHelperText classes={{root: classes.formHelperText}}>Elsőbbségi vezetes, így akár néhány hét alatt leteheted a forgalmi vizsgát!</FormHelperText>
                                {/*<FormControlLabel value="drivingOther" control={<Radio />} label="Áthelyezett tanuló" />*/}
                            </RadioGroup>
                        </FormControl>
                    </div>
                ) : (
                    <div className={classes.detailSearchButtonContainer} >
                        <Button
                            classes={{root: classes.detailSearchButton} }
                            color="secondary"
                            endIcon={detailSearch ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            onClick={handleDetailSearchClick}
                        >
                            Részletes keresés
                        </Button>
                    </div>
                ) : null}



                </form>
            <div className={classes.submitButtonContainer}>
                <Button classes={{root: classes.submitButtonRoot}} variant="contained" color="primary" size="large" onClick={onSubmit}>
                    Keresés
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} /> }
            </div>

        </>
    );
};

export default TurboSearchForm;
