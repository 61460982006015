import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import styles from './Page.style';

// @ts-ignore
const useStyles = makeStyles(styles);

export default function Page({title, content}) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Typography variant="h5" className={classes.title}>
                {title}
            </Typography>
            <Typography variant="body2" className={classes.content}>
                {content}
            </Typography>
        </div>

    );
};
