import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Rating from '@material-ui/lab/Rating';
import Button from '@material-ui/core/Button';
import get from 'lodash/get';
import styles from './School.style';
import clsx from "clsx";
import {getMatchingAddress} from '../util/Util';
import {getInfoByCategory} from "../config/AppConfig";
import {Tag} from "./Tag";
import capitalize from 'lodash/capitalize';

// @ts-ignore
const useStyles = makeStyles(styles);

const School: React.FC = (props) => {
    const classes = useStyles();
    const formater = new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    // @ts-ignore
    const { data, handleDetailsClick, filter, sortBy } = props;
    const {
        city: cityFilter,
        district: districtFilter,
        category: categoryFilter,
    } = filter;
    const reviews = get(data, 'reviews', {});
    const info = getInfoByCategory(categoryFilter);
    // @ts-ignore
    const address = `${capitalize(cityFilter)}, ${getMatchingAddress(data.addresses, districtFilter)} ker.`;
    const prices = get(data, `categories.${categoryFilter.toUpperCase()}`, {});

    const sortedBySum = sortBy === 'sum';
    const sortedByClass = sortBy === 'class';
    const sortedByDriving = sortBy === 'driving';// @ts-ignore
    // @ts-ignore
    const handleClick = () => {
        handleDetailsClick(data.itemId);
    };
    const countComponent = (<span className={classes.ratingCount}>({reviews.count} vélemény)</span>);
    const reviewComponent = reviews.link ? (<a href={reviews.link}>{countComponent}</a>) : countComponent;

    return (
        <Card variant="outlined" classes={{root: classes.cardRoot}}>
            <CardContent>
                <div className={classes.schoolName}>
                    {data.name}
                </div>
                <div className={classes.addresses}>
                    <span>{address}</span>
                </div>
                <div>
                    { reviews.value && (
                        <span className={classes.rating}>
                                { reviews.value && (<span className={classes.ratingValue}>{reviews.value} </span>) }
                            <Rating name="read-only" value={parseInt(reviews.value, 10)} readOnly size="small" />
                            { reviews.count && reviewComponent }
                            </span>
                    )}
                </div>
                { categoryFilter === 'b' && (
                    <div className={classes.tags}>
                        <Tag text="E-learning" tooltip="E-learning" active={prices.eClass} />
                        <Tag text="gyorsított" tooltip="Gyors jogosítvány. Általában heti háromszor 2 óra vezetés. Felár ellenében!" active={prices.drivingQuick} />
                        <Tag text="VIP" tooltip="Általában elsőbségi háztól-házig vezetés. Felár ellenében!" active={prices.drivingVip} />
                        <Tag text="délutáni vezetés" tooltip="Általában 16.00 után. Felár ellenében!" active={prices.drivingEvening} />
                        <Tag text="hétvégi vezetés" tooltip="Hétvégi vezetés. Felár ellenében!" active={prices.drivingWeekend} />
                        <Tag text="automata váltó" tooltip="Vezetés automata váltós autón. Felár ellenében!" active={prices.drivingAuto} />
                        <Tag text="gyakorló óra" tooltip="Jogosítvánnyal rendelkezök számára. Felár ellenében!" active={prices.drivingPractice} />
                    </div>
                )}
                <div className={classes.prices}>
                    <div className={classes.priceItem}>
                        <div className={clsx(classes.label, {[classes.sortByLabel]: sortedByDriving})}>
                            <div>Vezetés / óra</div>
                            {info.class && <div className={classes.infoText}>{info.driving}</div>}
                        </div>
                        <div className={clsx(classes.price, {[classes.sortByPrice]: sortedByDriving})}>
                                {prices.driving === 'na' ? '-' : `${formater.format(prices.driving)}Ft`}
                        </div>
                    </div>
                    <div className={classes.priceItem}>
                        <div className={clsx(classes.label, {[classes.sortByLabel]: sortedByClass})}>
                            <div>Elmélet</div>
                            {info.class && <div className={classes.infoText}>{info.class}</div>}
                        </div>
                        <div className={clsx(classes.price, {[classes.sortByPrice]: sortedByClass})}>
                                {prices.class === 'na' ? '-' : `${formater.format(prices.class)}Ft`}
                        </div>
                    </div>

                    <div className={classes.priceItem}>
                        <div className={clsx(classes.label, {[classes.sortByLabel]: sortedBySum})}>
                            <div>
                                Összesen
                            </div>
                            {info.class && <div className={classes.infoText}>{info.sum}</div>}
                        </div>
                        <div className={clsx(classes.price, {[classes.sortByPrice]: sortedBySum})}>
                                {formater.format(prices.sum)}Ft
                        </div>
                    </div>
                </div>
            </CardContent>
            <CardActions classes={{root: classes.cardActionRoot}}>
                <Button color="primary" onClick={handleClick} className={classes.moreInfoButton}>
                    Részletek
                </Button>
            </CardActions>
        </Card>
    );
}

export default School;
