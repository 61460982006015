export default theme => ({
    stickyTop: {
        /*position:'fixed',
        top: 56,*/
        width: '100%',
        // zIndex: 100,
    },
    container: {
        background: '#f1fafe',
        height: 'calc(100vh - 56px)',
        overflow: 'scroll'
    },
    content: {
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 40,
        // marginTop: 107,
        marginBottom: 24,
    },
    pageTitle: {
        margin: '16px 0px 0px',
        fontSize: 14,
        padding: '0px 10px',
        color: 'hsl(0, 0%, 40%)',
        textAlign: 'center',
        '& span': {
            fontWeight: 'bold',
        }
    },
    tileList: {
      // display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      gap: 25,
    },
    controls: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        gap: 5,
        padding: '5px 5px',
    },
    formControl: {
        minWidth: 150,

        '& .MuiInputBase-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
        }
    },

});
