import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SearchForm from '../components/SearchForm';
import styles from './Home.style';
import Links from "../components/Links";
import GovSup from "../adds/GovSup";
// @ts-ignore
import {Helmet} from 'react-helmet';
import TurboSearchForm from "../components/TurboSearchForm";

// @ts-ignore
const useStyles = makeStyles(styles);

export default function Home() {
    const classes = useStyles();

    return (
        <>
            <Helmet>
                <title>AUTÓSISKOLÁK, JOGOSÍTVÁNY, JOGSI - JogsiPláza - kresz tanfolyamok, autósiskolák, autó, motor vezetés, autó jogosítvány, motorkerékpár jogositvany</title>
                <meta name="keywords" content="autósiskolák, jogsi, jogosítvány, autós-motoros iskola, Budapest, autósiskola, motor jogosítvány, a jogosítvány, b jogosítvány, teherautó jogosítvány, busz jogosítvány, pótkocsi jogosítvány, autósiskolák, kresz tanfolyamok, jogosítvány kategóriák, oktatás, budapest, jogsi, iskolák, tanfolyam, járművezető, jogositvany" />
                <meta name="description" content="a legjobb autósiskolák budapesten, autósiskola budapest, autósiskolák budapesten, budapesti autósiskolák, e-learning, online kresz, gyakorlóvezetés jogosítvánnyal" />
            </Helmet>
            <div className={classes.container}>
                <div className={classes.topContent}>
                    <div className={classes.topContentInner}>
                        <Typography variant="h6" className={classes.homeTitle}>Jogosítványt szeretnél?</Typography>
                        <Typography variant="h6" className={classes.homeTitle}>Mi segítünk megtalálni a legjobb képzést!</Typography>
                        <Typography variant="subtitle1" className={classes.homeSubtitle}>Legyen is az a legolcsóbb, a legközelebbi vagy a legkényelmesebb.</Typography>

                        <TurboSearchForm defaultValues={{
                            district: 'ALL',
                            category: 'B',
                            class: 'class',
                            transmission: 'manual',
                            driving: 'driving',
                        }}/>

                        <GovSup />
                    </div>
                </div>

                <div className={classes.linksContent}>
                    <Links />
                </div>
            </div>
        </>
    );
};
