import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import styles from './Header.style';
import AppConfig from "../config/AppConfig";

const useStyles = makeStyles(styles);

const Header = ({onMenuClick}) => {
    const classes = useStyles();

   /* return (
        <header className={classes.header}>
            { /!* <div className={classes.logo}></div> *!/ }
            <Typography variant="subtitle1">
                <Link to="/">{AppConfig.name}</Link>
            </Typography>
        </header>
    ); */

    return (
        <AppBar position="static" classes={{root: classes.root}}>
            <Toolbar classes={{root: classes.toolbarRoot}}>
                <div className={classes.title}>
                    <Typography variant="h6">
                        {AppConfig.name}
                    </Typography>
                </div>

                <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={onMenuClick}
                >
                    <MenuIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
}

export default Header;
