import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import AppConfig from '../config/AppConfig';
import {useContextState} from '../context/State';
import SearchIcon from "@material-ui/icons/Search";
import PlainButton from "../components/PlainButton";

// @ts-ignore
const useStyles = makeStyles(() => ({
    buttonRoot: {
        '& .MuiButton-startIcon': {
            transform: 'rotate(90deg)'
        }
    }
}));

const { dataTypes } = AppConfig;

export default function SortByButton() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {state, dispatch} = useContextState();

    // @ts-ignore
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (sortByValue) => () => {
        handleClose();

        dispatch({
            type: 'setSortBy',
            payload: {
                property: 'sortBy',
                value: sortByValue
            },
        })
    };

    const selectedSortBy = dataTypes[state.sortBy].label;

    return (
        <>
            <PlainButton className={classes.buttonRoot} icon={<SyncAltIcon />} text={selectedSortBy} onClick={handleClick} />

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleMenuItemClick(dataTypes.driving.value)}>
                    <ListItemText primary={dataTypes.driving.label} />
                </MenuItem>
                <MenuItem onClick={handleMenuItemClick(dataTypes.class.value)}>
                    <ListItemText primary={dataTypes.class.label} />
                </MenuItem>
                <MenuItem onClick={handleMenuItemClick(dataTypes.sum.value)}>
                    <ListItemText primary={dataTypes.sum.label} />
                </MenuItem>
            </Menu>
        </>
    );
}