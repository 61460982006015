import React, {useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";

const styles = {
    root: {
        position: 'fixed',
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        padding: '25px 10px 10px 10px',
        textAlign: 'center'
    },
    text: {
        marginBottom: 25,
    },
    buttons: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    moreInfoButton: {
        color: '#0175FF',
    },
    agreeButton: {
        backgroundColor: '#6ba229',
    }
};
const useStyles = makeStyles(styles);

const LOCAL_STORAGE_KEY = 'jogsiplazaCookie';

const VisitorCookie = () => {
    const classes = useStyles();
    const history = useHistory();
    const [agree, setAgree] = useState(localStorage.getItem(LOCAL_STORAGE_KEY));
    const handleAgreeClick = () => {
        localStorage.setItem(LOCAL_STORAGE_KEY, true);
        setAgree(true);
    };
    const handleMoreInfoClick = () => {
        history.push('/adatvedelmi-szabalyzat');
    };

    if (agree) {
        return null;
    }

    return (
        <div className={classes.root}>
            <div className={classes.text}>
                Oldalunk a felhasználói élmény javítására sütiket (cookies) használ. A honlap böngészésével Ön elfogadja ezek használatát.
            </div>
            <div className={classes.buttons}>
                <Button color="primary" onClick={handleMoreInfoClick} className={classes.moreInfoButton}>
                    További információ
                </Button>
                <Button variant="contained" color="primary" size="large" onClick={handleAgreeClick} className={classes.agreeButton}>
                    Elfogadom
                </Button>
            </div>
        </div>
    );
};

export default VisitorCookie;
