import * as React from 'react'
import {useReducer} from "react";
import data from './all.json'
import AppConfig from "../config/AppConfig";
const { dataTypes } = AppConfig;

type Action = {
    type: string;
    payload: {
        property: string;
        value: string;
    };
};
type Dispatch = (action: Action) => void;
type State = {
    data: object[];
    filter: {
        places: string;
        categories: string;
    };
    sortBy: string;
    selectedSchool: string
};
type Context = {
  state: State;
  dispatch: Dispatch;
};
type StateProviderProps = {
    children: React.ReactNode;
};

const defaultState:State = {
    data: data,
    filter: {
        places: '',
        categories: ''
    },
    sortBy: dataTypes.sum.value,
    selectedSchool: ''
};

const StateContext = React.createContext<Context | undefined>(undefined);

const stateReducer = function(state: State, action: Action) {
    switch (action.type) {
        case 'setFilter': {
            const newFilter = { ...state.filter, [action.payload.property]: action.payload.value };
            return {...state, filter: newFilter};
        }
        case 'setSortBy': {
            return {...state, sortBy: action.payload.value};
        }
        case 'setSelectedSchool': {
            return {...state, selectedSchool: action.payload.value};
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
};

function StateProvider({children}: StateProviderProps) {
    // @ts-ignore
    const [state, dispatch] = React.useReducer(stateReducer, defaultState);

    return (
        <StateContext.Provider value={{state, dispatch}}>
            {children}
        </StateContext.Provider>
    )
}

function useContextState() {
    const context = React.useContext(StateContext);

    if (context === undefined) {
        throw new Error('useContextState must be used within a CountProvider');
    }

    return context;
}

export {StateProvider, useContextState};