export default theme => ({
    container: {
        padding: '40px 20px 40px 20px',
        background: '#1f2433',
        color: '#b0bec5',
        textAlign: 'center',
    },
    logo: {
        marginBottom: 30,
    },
    legal: {
    },
    spacing: {
        marginBottom: 20,
    }
});
