import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import HomeIcon from "@material-ui/icons/Home";
import LockOpen from "@material-ui/icons/LockOpen";
import Drawer from "@material-ui/core/Drawer";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import AppConfig from "../config/AppConfig";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles({
    root: {
        minWidth: 250,
        maxWidth: 250,
        background: '#38475d',
        color: 'white',
    },
    title: {
        padding: '12px 0px',
        width: '100%',
        textAlign: 'center',
        background: '#243238',
    },
    listItem: {
        paddingTop: 10,
        paddingBottom: 10,
        '& .MuiListItemText-root': {
            color: 'white',
            fontWeight: 500,
            textDecoration: 'none',
            textTransform: 'uppercase',
            letterSpacing: '0.07em',
        },
    }
});

const DrawerMenu = ({open, onClose}) => {
    const classes = useStyles();
    const history = useHistory();
    const onMenuItemClick = (to) => () =>{
        onClose();
        history.push(to);
    };

    return (
        <Drawer anchor="right" open={open} onClose={onClose} classes={{paper: classes.root}}>
            <div className={classes.drawerInner}>
                <Typography variant="h6" className={classes.title}>{AppConfig.name}</Typography>

                <List>
                    <ListItem button key="home" className={classes.listItem}>
                        <ListItemIcon><HomeIcon style={{fill: "white"}} /></ListItemIcon>
                        <ListItemText onClick={onMenuItemClick('/')} primary="Kezdőoldal" />
                    </ListItem>

                    <ListItem button key="forschools" className={classes.listItem}>
                        <ListItemIcon><LockOpen style={{fill: "white"}} /></ListItemIcon>
                        <ListItemText onClick={onMenuItemClick('/adatvedelmi-szabalyzat')} primary="Adatvédelmi Szabályzat" />
                    </ListItem>
                </List>
            </div>
        </Drawer>
    );
};

export default DrawerMenu;