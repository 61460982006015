import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Tooltip from "@material-ui/core/Tooltip";
import clsx from "clsx";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

// @ts-ignore
const useStyles = makeStyles(() => ({
    tag:{
        padding: '3px 7px',
        borderRadius: 3,
        marginRight: 5,
        backgroundColor: '#e6e6e6',
        color: '#d5d5d5',
        fontSize: '70%',
        fontWeight: 700,
        textTransform: 'uppercase',
        '&.active': {
            backgroundColor: '#6ba229',
            color: 'white',
        }

    },
}));

export const Tag = ({text, tooltip, active}) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    /*return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                PopperProps={{
                    disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={tooltip}
            >
                <span onClick={handleTooltipOpen} className={clsx(classes.tag, {'active': active})}>{text}</span>
            </Tooltip>
        </ClickAwayListener>
    );*/
    return (<span onClick={handleTooltipOpen} className={clsx(classes.tag, {'active': active})}>{text}</span>);
}