import React, {useCallback, useState} from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import './App.css';
import {StateProvider} from './context/State';
import theme from './theme/theme';
import {ThemeProvider} from '@material-ui/core';
import Header from "./components/Header";
import Footer from "./components/Footer";
import DrawerMenu from "./components/DrawerMenu";
import VisitorCookie from "./components/VisitorCookie";
import DetailsModal from "./components/DetailsModal";
import {makeStyles} from "@material-ui/core/styles";
import Roots from "./Roots";
import { Helmet } from "react-helmet";

const useStyles = makeStyles(theme => ({
    content: {
        height: 'calc(100vh - 56px)',
        overflow: 'scroll'
    }
}));

const App: React.FC = () => {
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleDrawerClose = useCallback(() => {
        setDrawerOpen(false);
    }, [setDrawerOpen]);
    const handleDrawerToggle = useCallback(() => {
        setDrawerOpen(!drawerOpen);
    }, [drawerOpen, setDrawerOpen]);

    return (
        <ThemeProvider theme={theme}>
            <StateProvider>
                <>
                    <Helmet>
                        <script async src="https://www.googletagmanager.com/gtag/js?id=G-736GC418H6"></script>
                    </Helmet>
                    <Helmet
                        script={[{
                            type: 'text/javascript',
                            innerHTML: `window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());

                            gtag('config', 'G-736GC418H6');`
                        }]}
                    />
                    <Header onMenuClick={handleDrawerToggle}/>

                    <Router>
                        <Roots />

                        <Footer />

                        <DrawerMenu open={drawerOpen} onClose={handleDrawerClose} />

                        <DetailsModal />
                        <VisitorCookie />

                    </Router>

                </>

            </StateProvider>
        </ThemeProvider>
    );
}

export default App;
