export default theme => ({
    container: {
        height: 'calc(100vh - 56px)',
        overflow: 'scroll',
    },
    topContent: {
        padding: '40px 20px 40px 20px',
        minHeight: 'calc(100vh - 56px)',
        backgroundColor: '#f1fafe',
        background: 'linear-gradient(96.44deg,#c5d5ff,#e2f0ff 51.56%,#d7d4ff)',
    },
    topContentInner: {
        maxWidth: 500,
        margin: '0px auto',
    },
    homeTitle: {
        textTransform: 'uppercase',
        marginBottom: 10,
        color: '#243238',
        fontWeight: 700,
        letterSpacing: '.05em',
        textAlign: 'center',
    },
    homeSubtitle: {
        marginBottom: 30,
        color: '#243238',
        textAlign: 'center',
    },

    linksContent: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: '40px 20px 20px 20px',
        background: '#f1fafe',
        boxShadow: 'inset 0px 2px 4px -2px rgb(0 0 0 / 20%)',
    },
});