const AppConfig = {
    name: 'JogsiPláza',
    dataTypes: {
        driving: {
            label: 'Vezetés',
            value: 'driving',
        },
        class: {
            label: 'Elmélet',
            value: 'class',
        },
        sum: {
            label: 'Összesen',
            value: 'sum',
        }
    },
    places: [
        {
            value: 'ALL',
            label: 'Budapest',
        },
        {
            value: 'I',
            label: 'Budapest, I ker.',
        },
        {
            value: 'II',
            label: 'Budapest, II ker.',
        },
        {
            value: 'III',
            label: 'Budapest, III ker.',
        },
        {
            value: 'IV',
            label: 'Budapest, IV ker.',
        },
        {
            value: 'V',
            label: 'Budapest, V ker.',
        },
        {
            value: 'VI',
            label: 'Budapest, VI ker.',
        },
        {
            value: 'VII',
            label: 'Budapest, VII ker.',
        },
        {
            value: 'VIII',
            label: 'Budapest, VIII ker.',
        },
        {
            value: 'IX',
            label: 'Budapest, IX ker.',
        },
        {
            value: 'X',
            label: 'Budapest, X ker.',
        },
        {
            value: 'XI',
            label: 'Budapest, XI ker.',
        },
        {
            value: 'XII',
            label: 'Budapest, XII ker.',
        },
        {
            value: 'XIII',
            label: 'Budapest, XIII ker.',
        },
        {
            value: 'XIV',
            label: 'Budapest, XIV ker.',
        },
        {
            value: 'XV',
            label: 'Budapest, XV ker.',
        },
        {
            value: 'XVI',
            label: 'Budapest, XVI ker.',
        },
        {
            value: 'XVII',
            label: 'Budapest, XVII ker.',
        },
        {
            value: 'XVIII',
            label: 'Budapest, XVIII ker.',
        },
        {
            value: 'XIX',
            label: 'Budapest, XIX ker.',
        },
        {
            value: 'XX',
            label: 'Budapest, XX ker.',
        },
        {
            value: 'XXI',
            label: 'Budapest, XXI ker.',
        },
        {
            value: 'XXII',
            label: 'Budapest, XXII ker.',
        },
        {
            value: 'XXIII',
            label: 'Budapest, XXIII ker.',
        },
    ],

    categories : {
        'AM'   : {
            'code' : 'AM',
            'name' : 'Segédmotorkerékpár',
            'desc' : '14 éves kortól legfeljebb 50cm3-es segédmotorkerékpár, illetve mopedautó vezetésére jogosít.',
            'includeInForm': true,
        },

        'A1'   : {
            'code' : 'A1',
            'name' : 'Motorkerékpár ( <=125cm3 )',
            'desc' :  '16 éves kortól legfeljebb 125cm3-es és legfeljebb 11kW teljesítményű motorkerékpár vezetésére jogosít.',
            'includeInForm': true
        },

        'BA1'   : {
            'code' : 'BA1',
            'name' : 'Motorkerékpár ( <=125cm3 )',
            'desc' :  'A1 kategória érvényes "B" kategóriás vezetői engedély esetén',
            'includeInForm': false
        },

        'A2'   : {
            'code' : 'A2',
            'name' : 'Motorkerékpár ( korlátozott )',
            'desc' :  '18 éves kortól legfeljebb 35kW teljesítményig, de legfeljebb 0.2kW/kg teljesítmény, tömeg arányig.',
            'includeInForm': true
        },

        'A1A2'   : {
            'code' : 'A1A2',
            'name' : 'Motorkerékpár ( korlátozott )',
            'desc' :  '1 évnél nem régebben meglévő "A1" jogosítvánnyal rendelkezőknek.',
            'includeInForm': false
        },

        'A'   : {
            'code' : 'A',
            'name' : 'Motorkerékpár ( korlátozás nélkül )',
            'desc' :  '24 éves kortól',
            'includeInForm': true
        },

        'A1A2A'   : {
            'code' : 'A1A2A',
            'name' : 'Motorkerékpár ( korlátozás nélkül )',
            'desc' :  '1 évesnél nem régebben meglévő "A1" vagy "A korlátozott" jogosítvánnyal rendelkezőknek.',
            'includeInForm': false
        },

        'B'   : {
            'code' : 'B',
            'name' : 'Személygépkocsi és kistehergépkocsi',
            'desc' :  '',
            'includeInForm': true,
            'info': {
                class: 'Tantermi elméleti oktatás',
                eClass: 'Online elméleti oktatás',
                driving: 'Járművezetési gyakorlat alap óradíj (egy oktatási óra időtartama általában 50 perc).',
                drivingLabel: 'Normal',
                manual: 'Manuális váltós gépjármű',
                drivingAuto: 'Automata váltós gépjármű',
                drivingQuick: 'Járművezetési gyakorlat óradíj, általában hétfőtől péntekig heti minimum 3 alkalom, de ez iskolánként eltérhet (egy oktatási óra időtartama általában 50 perc).',
                drivingQuickLabel: 'Intenzív',
                drivingEvening: 'Járművezetési gyakorlat óradíj, hétfőtől péntekig 17.00 óra után, de ez iskolánként eltérhet (egy oktatási óra időtartama általában 50 perc).',
                drivingEveningLabel: 'Esti',
                drivingWeekend: 'Járművezetési gyakorlat óradíj, hétvégi és ünnepnapi vezetés (egy oktatási óra időtartama általában 50 perc).',
                drivingWeekendLabel: 'Hétvégi',
                drivingPractice: 'Járművezetési gyakorlat óradíj, gyakorló vezetés már jogosítvánnyal rendelkezők számára (egy oktatási óra időtartama általában 50 perc).',
                drivingPracticeLabel: 'Gyakorló',
                drivingVip: 'Elsőbbségi vezetes, így akár néhány hét alatt leteheted a forgalmi vizsgát.',
                drivingVipLabel: 'VIP',
                sum: 'Vizsgadíjak nélkül, elméleti oktatás + járművezetési gyakorlat (30 óra)',
            }
        },

        'C1'   : {
            'code' : 'C1',
            'name' : 'Tehergépkocsi 7 500kg-ig',
            'desc' :  '',
            'includeInForm': true
        },

        'C'   : {
            'code' : 'C',
            'name' : 'Tehergépkocsi',
            'desc' :  '',
            'includeInForm': true
        },

        'D1'   : {
            'code' : 'D1',
            'name' : 'Autóbusz legfeljebb 16 utassal',
            'desc' :  '',
            'includeInForm': true
        },

        'D'   : {
            'code' : 'D',
            'name' : 'Autóbusz',
            'desc' :  '',
            'includeInForm': true
        },

        'BE'   : {
            'code' : 'BE',
            'name' : 'Nehézpótkocsi "B" kategóriás vontatójárművel',
            'desc' :  '',
            'includeInForm': true
        },

        'C1E'   : {
            'code' : 'C1E',
            'name' : 'Nehézpótkocsi "C1" kategóriás vontatójárművel',
            'desc' :  '',
            'includeInForm': true
        },

        'CE'   : {
            'code' : 'CE',
            'name' : 'Nehézpótkocsi "C" kategóriás vontatójárművel',
            'desc' :  '',
            'includeInForm': true
        },

        'D1E'   : {
            'code' : 'D1E',
            'name' : 'Nehézpótkocsi "D1" kategóriás vontatójárművel',
            'desc' :  '',
            'includeInForm': true
        },

        'DE'   : {
            'code' : 'DE',
            'name' : 'Nehézpótkocsi "D" kategóriás vontatójárművel',
            'desc' :  '',
            'includeInForm': true
        },

        'T'   : {
            'code' : 'T',
            'name' : 'Traktor és lassú jármű',
            'desc' :  '',
            'includeInForm': false
        },

        'MK'   : {
            'code' : 'M,K',
            'name' : 'Kerti traktor és állati erővel vontatott jármű',
            'desc' :  '',
            'includeInForm': false
        }
    },
};

export const findPlaceByValue = value => {
    const match = AppConfig.places.find(item => item.value === value);

    return match  || AppConfig.places[0];
};

export const findCategoryByValue = value => {
    return Object.values(AppConfig.categories).find(item => item.code === value);
}

export const getInfoByCategory = categories => {
    return AppConfig.categories[categories.toUpperCase()].info || {};
};

export default AppConfig;