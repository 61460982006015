import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Modal from './Modal';
import SearchForm from "./SearchForm"
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import styles from "./PlainButton.style";
import {makeStyles} from "@material-ui/core";

// @ts-ignore
const useStyles = makeStyles(styles);

type PlainButtonProps = {
    onClick: (e: Event) => void;
    text: string;
    icon: React.ReactNode;
};

export default function PlainButton({text, icon, onClick, ...rest}: PlainButtonProps) {
    const classes = useStyles();

    // @ts-ignore
    return (<Button classes={{
        root: classes.root,
        disabled: classes.disabled,
    }}
    startIcon={icon}
    onClick={onClick}
    size="large"
    {...rest}>
        {text}
    </Button>);
};
