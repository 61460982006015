export default theme => ({
    /* root: {
        position: 'fixed'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    logo: {
        marginRight: theme.spacing(),
        background: 'url("/images/pin.png") no-repeat center center',
        backgroundSize: '25px 53px',
        width: 25,
        height: 53
    },

    header: {
        width: '100%',
        height: 53,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgb(49, 60, 83)',
        color: 'white',
        '& a': {
            color: 'white',
            textDecoration: 'none'
        }
    } */
    root: {
        flexGrow: 1,
    },
    toolbarRoot: {
        padding: 0,
    },
    menuButton: {

    },
    title: {
        flexGrow: 1,
        marginLeft: theme.spacing(2),
    },
});
