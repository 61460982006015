export const formatFullAddress = address => `${address.postcode || ''} ${address.city} ${address.district}, ${address.street || ''} ${address.houseNo || ''}`;
export const formatAddress = address => `${address.district}`;
export const getMatchingAddress = (addresses = [], district, fullAddress) => {
    let match = addresses;

    if (district && !district === 'ALL') {
        match = addresses.filter(item => item.district === district);
    }

    return match.map(fullAddress ? formatFullAddress : formatAddress);
}
export const findSchoolById = (schools = [], id) => schools.filter(item => item.itemId === id)[0];