export default theme => ({
    container: {
        padding: '40px 20px 40px 20px',
    },
    title: {
        marginBottom: 40,
        textAlign: 'center',
    },
    content: {
        textAlign: 'justify'
    }
});
