import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppConfig from "../config/AppConfig";
import styles from './Footer.style';

// @ts-ignore
const useStyles = makeStyles(styles);

const Footer: React.FC = () => {
    const classes = useStyles();

    return (
        <footer className={classes.container}>
                <Typography variant="subtitle1" className={classes.logo}>
                    {AppConfig.name}
                </Typography>
                <div className={classes.legal}>
                    <Typography variant="body2" className={classes.spacing}>
                        Minden jog fenntartva © 2021 jogsiplaza.hu
                    </Typography>
                    <Typography variant="body2">
                        A közúti közlekedésről szóló 1988. évi I. törvény 18. § (2) bek. alapján, a közúti járművezetők és a közúti közlekedési szakemberek képzésére, továbbképzésére, utánképzésére engedéllyel rendelkező szervezetek (autósiskolák) vonatkozásában, jelen honlap, a közlekedési hatóság által vezetett nyilvántartásból, mint közhiteles hatósági nyilvántartásból származtatja a szervezetek honlapon megjelenő adatait.
                    </Typography>
                </div>
        </footer>
    );
}

export default Footer;
